import type { BaseSyntheticEvent } from 'react';
import { useCallback, useContext } from 'react';
import type { FieldErrors, SubmitErrorHandler } from 'react-hook-form';
import { useFeature } from '@growthbook/growthbook-react';
import { useRouter } from 'next/router';

import { useAppDispatch, useAppSelector, useNeverBounce } from '@/hooks';
import { SignupFormCtx } from '@/shared/signup-form-v2/SignupFormV2';
import type { HomeCtaPricingPlans, PaddlePricingPlan } from '@/types/pricing-plans.types';
import { defaultPricingPlan, homeCtaPricingPlans, paddlePricingPlans, pricingTypeMap } from '@/types/pricing-plans.types';
import type { SignupData, SignUpFormData } from '@/types/signup.types';
import { getCookie } from '@/utils/cookies.util';
import trackingUtils, { EVENT_NAMES } from '@/utils/tracking.util';

import { signupThunks } from '../../redux/features/signupSlice';
import { getUTMData } from '../../utils/utm.utils'

type ValidFormReturn = {
  type: 'neverbounce' | string;
  status: 'invalid' | 'none';
  payload?: ErrorResponseData;
}

type ErrorResponseData = {
  error: {
    data: Record<string, unknown>
  };
  detail: {
    status: string;
    sub_status: string;
  };
  thrown: string;
  message: string;
};

export const useSignupFormSubmit = (formId: string) => {
  const dispatch = useAppDispatch();
  const usersCount = useAppSelector(state => state.pricingPlan.usersCount);
  const rawPricingPlan = useAppSelector(state => state.pricingPlan.planType);

  const { query } = useRouter();
  const { eventCategory, splitTests } = useContext(SignupFormCtx);
  const trackingState = useAppSelector(state => state.tracking);

  const { emailValidation } = useNeverBounce(eventCategory);
  const billingProviderFeature = useFeature<SignupData['provider']>('billing-payment-provider')?.value ?? 'paddle';

  /** Prepares the required data for the signup API call */
  const preparePayloadData = useCallback(({ name, email, password, companySize, phoneNumber }: SignUpFormData): SignupData => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const queryParams = query as Record<string, string>;
    const homeCta = (queryParams?.['homecta'] ?? trackingState.signupTrigger) as keyof HomeCtaPricingPlans;
    const isPartnerStack = getCookie('__td_pstack_gspk') && getCookie('__td_pstack_gsxid');
    const partnerStackPayload = isPartnerStack ? [{ name: 'partnerStack', value: true }] : [];
    const selectedPricingPlan = (homeCtaPricingPlans[homeCta] || rawPricingPlan) as PaddlePricingPlan;
    const getPricingPlanByProvider = (plan : PaddlePricingPlan) => billingProviderFeature === 'paddle' ? paddlePricingPlans[plan] : plan;
    const utmData = getUTMData();

    return {
      name,
      email,
      password,
      company: `${ name }'s Company`,
      planUsers: usersCount,
      planType: pricingTypeMap.get(defaultPricingPlan)!,
      pricingPlan: getPricingPlanByProvider(defaultPricingPlan),
      provider: billingProviderFeature,
      minBillableUsers: 1,
      timezone,
      splitTest: [
        { name: 'popup-signup-trigger', value: homeCta },
        { name: 'company-size', value: companySize },
        { name: 'phone-number', value: phoneNumber },
        { name: 'selected-pricing-plan', value: getPricingPlanByProvider(selectedPricingPlan) },
        ...partnerStackPayload,
        ...(splitTests ? splitTests : []),
      ],
      utmData,
    };
  }, [billingProviderFeature, query, rawPricingPlan, splitTests, trackingState, usersCount]);

  /** Handles action when the signup form is invalid. This includes the tracking for unsuccessful signup */
  const invalidFormHandler: SubmitErrorHandler<SignUpFormData> = useCallback((errors, event) => {
    const properties = submitErrProps(errors, event, `-${ formId }`);

    trackingUtils.trackEvent({
      event: EVENT_NAMES.GENERAL.SIGNUP_UNSUCCESSFUL,
      category: eventCategory,
      properties,
    });
  }, [eventCategory, formId]);

  /** Handles the signup form submit and validations associated */
  const validFormHandler = useCallback(async (data: SignUpFormData): Promise<ValidFormReturn> => {
    try {
      const validationStatus = await emailValidation(data.email);
      if (validationStatus === 'invalid') {
        throw new Error('Neverbounce failed');
      }
      const signupPayload = preparePayloadData(data);
      const { type, payload } = await dispatch(signupThunks.trySignup({ data: signupPayload, isModal: false }));
      return { type, status: 'none', payload: payload as unknown as ErrorResponseData };
    } catch (e) {
      return { type: 'neverbounce', status: 'invalid' };
    }
  }, [dispatch, emailValidation, preparePayloadData]);

  return { invalidFormHandler, validFormHandler };
};


const fieldNames: Record<keyof SignUpFormData, string> = {
  name: 'name',
  email: 'email',
  password: 'password',
  companySize: 'company size',
  phoneNumber: 'phone number',
};

function submitErrProps(
  errors: FieldErrors<SignUpFormData>,
  e: BaseSyntheticEvent | undefined,
  idSuffix = '',
) {
  let properties = {};

  const keys = Object.keys(errors) as (keyof SignUpFormData)[];
  for (const key of keys) {
    const errorObj = errors[key];

    if (errorObj?.message) {
      const value = e?.target?.querySelector(`#${ key }${ idSuffix }`)?.value;

      properties = {
        ...properties,
        [`error${ key.toLowerCase() }`]: {
          errormessage: errorObj.message,
          field: fieldNames[key],
          ...(value ? { value } : {}),
        },
      };
    }
  }

  return properties;
}
