import type { FC, HTMLAttributes } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import type { SubmitErrorHandler, SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useFeature } from '@growthbook/growthbook-react';
import { yupResolver } from '@hookform/resolvers/yup';

import { CompanySize, companySizes, newCompanySizes } from '@/components/register/company-size';
import { PhoneNumberInput } from '@/components/register/phone-number';
import type { TdLoadingButtonStyle } from '@/components/uikit/button/TdLoadingButton';
import TdLoadingButton from '@/components/uikit/button/TdLoadingButton';
import FlexBox from '@/components/uikit/flex-box/FlexBox';
import { FormInputProvider, TdInput } from '@/components/uikit/Input';
import { useAppDispatch, useAppSelector, useSignupFormSubmit } from '@/hooks';
import RenderIf from '@/shared/render-if';
import type {
  AsyncEmailErr,
  InputContainerType,
  InputData,
  SignupCtxData,
  SignUpFormData,
  SignUpFormTouchedFields,
} from '@/types/signup.types';
import { AsyncEmailErrValues } from '@/types/signup.types';
import trackingUtils from '@/utils/tracking.util';

import { validationSchema, validationSchemaWeb3 } from '../../../helpers/validation.schema';
import { resetFailure } from '../../../redux/features/signupSlice';

import styles from './SignupFormV2.module.scss';

type InputStyle = {
  shape?: 'rounded' | 'normal';
  labelMode?: 'label' | 'placeholder';
  errorDisplay?: 'text' | 'tooltip';
}

type FormProps = {
  formId: string;
  formZoomInfoId?: string;
  inputs: InputData[];
  btnText?: string;
  btnFullWidth?: boolean;
  inputsStyle?: InputStyle;
  buttonStyle?: TdLoadingButtonStyle;
  direction?: 'row' | 'column';
  containerType?: InputContainerType;
} & HTMLAttributes<HTMLFormElement>

const touchedFieldsInitState: SignUpFormTouchedFields = {
  name: false,
  email: false,
  password: false,
  companySize: false,
  phoneNumber: false,
};

const defaultInputStyle: InputStyle = {
  shape: 'normal',
  labelMode: 'label',
  errorDisplay: 'text',
};

const SignupFormV2: FC<FormProps> = (
  {
    btnText = 'Create account',
    btnFullWidth = true,
    direction = 'column',
    formId,
    containerType = 'FormControl',
    formZoomInfoId,
    inputsStyle,
    buttonStyle = 'legacy',
    ...allProps
  },
) => {
  const [asyncEmailErr, setAsyncEmailErr] = useState<AsyncEmailErr>();
  const [touchedFields, setTouchedFields] = useState<SignUpFormTouchedFields>(touchedFieldsInitState);
  const [isLoading, setIsLoading] = useState(false);
  const { trackOnSubmitEvent } = useContext(SignupFormCtx);
  const apiErrors = ['loginExists', 'loginExistsTdc', 'inviteOnlyDomain'];

  const useValidationSchema = containerType === 'InputBox' ? validationSchemaWeb3 : validationSchema;
  const formProps = useForm<SignUpFormData>({
    resolver: yupResolver(useValidationSchema),
    mode: 'all',
  });
  const companySize = formProps.watch('companySize');

  const dispatch = useAppDispatch();

  const emailError = useAppSelector(state => state.signup.error);
  const { invalidFormHandler, validFormHandler } = useSignupFormSubmit(formId);

  const handleApiTrackingError = (message: string, error: string) => {
    trackingUtils.trackEvent({
      event: 'API Validation Error',
      properties: {
        email: formProps.getValues().email,
        name: 'main-form',
        message,
        error,
      },
    });
  };

  const handleInvalidForm: SubmitErrorHandler<SignUpFormData> = (errors, event) => {
    setTouchedFields({ name: true, email: true, password: true, companySize: true, phoneNumber: true });
    invalidFormHandler(errors, event);
  };
  const showNewSegments = useFeature('new-company-size-segments').value ?? false;

  const companySizesToShow = showNewSegments ? newCompanySizes : companySizes;
  const firstCompanySizeValue = companySizesToShow[0].value;
  const withPhoneNumber = () => !!companySize && companySize !== firstCompanySizeValue;
  const handleValidForm: SubmitHandler<SignUpFormData> = async (data, e) => {
    e?.preventDefault();
    setTouchedFields({ name: true, email: true, password: true, companySize: true, phoneNumber: true });
    setIsLoading(true);

    const { type, status, payload } = await validFormHandler(data);
    if (type === 'neverbounce' && status === 'invalid') setAsyncEmailErr('neverbounce');
    if (type === 'signup/rejected' && payload && payload.error) {
      const signupError = payload.error?.data;
      if (apiErrors.includes(signupError && signupError?.error as string)) {
        setAsyncEmailErr(signupError.error as AsyncEmailErr);
      } else {
        setAsyncEmailErr('apiError');
      }
      handleApiTrackingError(signupError?.message as string, signupError?.error as string);
    }
    setIsLoading(false);
  }

  const handleSubmitBtnClick = () => {
    if (trackOnSubmitEvent) {
      trackingUtils.trackEvent({
        event: trackOnSubmitEvent,
        properties: {
          email: formProps.getValues().email,
          name: 'main-form',
        },
      });
    }
  };

  useEffect(() => {
    if (emailError && AsyncEmailErrValues.includes(emailError)) {
      setAsyncEmailErr(emailError as AsyncEmailErr);
      dispatch(resetFailure());
      setIsLoading(false);
    }
  }, [emailError, dispatch]);

  const btnDefaultStyle = buttonStyle === 'default';

  return (
    <FormProvider { ...formProps }>
      <FormInputProvider
        value={{ touchedFields, asyncEmailErr, formId: 'register', setAsyncEmailErr, setTouchedFields }}>
        <form
          id={ `${ formZoomInfoId }` }
          noValidate
          className={ styles.SignupFormV2 }
          onSubmit={ formProps.handleSubmit(handleValidForm, handleInvalidForm) }
          aria-label={ `${ formId } form` }
          { ...allProps }
        >
          <FlexBox
            flexDirection={ direction } columnGap={ 16 }
            className={ styles.SignupFormV2__InputsWrapper }>
            <TdInput
              key="name"
              autoComplete="organization"
              rounded={ true }
              labelMode={ inputsStyle?.labelMode || defaultInputStyle.labelMode }
              errorDisplay={ inputsStyle?.errorDisplay || defaultInputStyle.errorDisplay }
              inputId={ `name-${ formId }` }
              containerType={ containerType }
              variety="name"
              label="Name*"
              placeholder="Full name"
              variant="outlined"
            />

            <TdInput
              key="email"
              rounded={ true }
              labelMode={ inputsStyle?.labelMode || defaultInputStyle.labelMode }
              errorDisplay={ inputsStyle?.errorDisplay || defaultInputStyle.errorDisplay }
              inputId={ `email-${ formId }` }
              containerType={ containerType }
              variety="email"
              label="Business email*"
              placeholder="name@example.com"
              variant="outlined"
            />

            <CompanySize />
            <RenderIf condition={ withPhoneNumber() }>
              <PhoneNumberInput />
            </RenderIf>

            <TdInput
              key="password"
              rounded={ inputsStyle?.shape === 'rounded' || false }
              labelMode={ inputsStyle?.labelMode || defaultInputStyle.labelMode }
              errorDisplay={ inputsStyle?.errorDisplay || defaultInputStyle.errorDisplay }
              inputId={ `password-${ formId }` }
              containerType={ containerType }
              variety="password"
              label="Password*"
              placeholder="Password"
              variant="outlined"
            />

          </FlexBox>
          <TdLoadingButton
            onClick={ handleSubmitBtnClick }
            loading={ isLoading }
            type="submit"
            variant="contained"
            fullWidth={ btnFullWidth }
            disableElevation={ btnDefaultStyle }
            uppercase={ !btnDefaultStyle }
            btnStyle={ buttonStyle }
            className={ styles.CreateAccountBtn }
          >
            { btnText }
          </TdLoadingButton>
        </form>
      </FormInputProvider>
    </FormProvider>
  );
};
export default SignupFormV2;

export const SignupFormCtx = createContext<SignupCtxData>({
  engagementEvent: '',
  eventCategory: '',
  engagementName: '',
  splitTests: [],
});

export const SignupFormCtxProvider = SignupFormCtx.Provider;
