import Cookies from 'js-cookie';

export function captureUTMParameters() {
  // Parse the URL parameters to get UTM values
  const urlParams = new URLSearchParams(window.location.search);

  // Define UTM parameter keys
  const utmKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'];

  // Loop through UTM keys and store them in cookies
  utmKeys.forEach((key) => {
    const value = urlParams.get(key);
    if (value) {
      Cookies.set(key, value, { expires: 7, path: '/' }); // 7-day expiry
    }
  });

  // Set "First Page Seen" if not already set
  if (!Cookies.get('firstPageSeen')) {
    Cookies.set('firstPageSeen', window.location.href, { path: '/' });
  }
}

export function getUTMData() {
  return {
    utm_source: Cookies.get('utm_source'),
    utm_medium: Cookies.get('utm_medium'),
    utm_campaign: Cookies.get('utm_campaign'),
    utm_content: Cookies.get('utm_content'),
    utm_term: Cookies.get('utm_term'),
    first_page_seen: Cookies.get('firstPageSeen'),
  };
}
