import type { AxiosResponse } from 'axios';

import type { PlanId } from '../features/pricing-plans/types';

import type { PricingPlan } from './pricing-plans.types';
import type { ExpSplitTest } from './tracking.types';

type AllBooleans<T> = {
  [P in keyof T]: boolean;
};

export type LoggedUserType = {
  name?: string;
  email?: string;
}

export type SignupPayload = {
  data: SignupData;
  isModal: boolean;
}

export type SignupData = {
  name: string;
  email: string;
  password: string;
  company: string;
  planUsers: number;
  planType: number;
  pricingPlan: PricingPlan | PlanId,
  provider: 'paddle' | 'stripe';
  minBillableUsers: number;
  timezone: string;
  splitTest: ExpSplitTest[];
  utmData?: {
    utm_source: string | undefined,
    utm_medium: string | undefined,
    utm_campaign: string | undefined,
    utm_content: string | undefined,
    utm_term: string | undefined,
    first_page_seen:string | undefined,
  };
}

export type SignupState = {
  error: string | null;
  errorModal: string | null;
  errorMessage: string | null;
  isLoadingData: boolean;
  showModal: boolean;
  showModalTry: boolean;
  showModalTrial: boolean;
  userData: Partial<SignupData>;
  appWebUrl: string;
}

export type SignUpFormData = {
  email: string;
  password: string;
  name: string;
  companySize: string;
  phoneNumber: string;
}

export type SignUpFormTouchedFields = AllBooleans<SignUpFormData>;

export type TdcDataResponse = {
  user_exists: boolean;
  company_owner?: boolean;
  is_admin?: boolean;
}

export type Td2DataResponse = {
  hsSubmit?: boolean;
  data: {
    token: string;
    userId?: string;
    companyId?: string;
    workspaceId?: string;
    noWorkspaces?: boolean;
  };
}

export type SignUpErrorPayload = {
  error: AxiosResponse<SignUpError | unknown>,
  isModal: boolean,
}

export type SignUpError = {
  error: string,
  message: string,
}

export type InputVariant = 'standard' | 'outlined' | 'filled';

export type InputContainerType = 'InputBox' | 'FormControl' | undefined;

export type InputData = {
  variety: keyof SignUpFormData;
  label: string;
  placeholder?: string;
  variant?: InputVariant;
}

export type SignupCtxData = {
  eventCategory: string;
  engagementEvent: string;
  engagementName: string;
  splitTests?: ExpSplitTest[];
  trackOnSubmitEvent?: string;
}

export type FormId = 'home' | 'popup' | 'register';

export type AsyncEmailErr = 'inviteOnlyDomain' | 'loginExists' | 'loginExistsTdc' | 'neverbounce' | 'apiError';

export const AsyncEmailErrValues = ['loginExists', 'loginExistsTdc', 'inviteOnlyDomain']

export function hasProp<K extends PropertyKey>(data: object, prop: K): data is Record<K, unknown> {
  return prop in data;
}

export function isSignUpError(data: unknown): data is SignUpError {
  return typeof data === 'object'
    && data != null
    && hasProp<keyof SignUpError>(data, 'error')
    && hasProp<keyof SignUpError>(data, 'message');
}

export interface PasswordRule {
  label: string;
  validate(value: string): boolean;
}
