import type { FC } from 'react';
import styled from '@emotion/styled';
import type { SbBlokData } from '@storyblok/js';
import { storyblokEditable } from '@storyblok/react';

import type { VideoStoryblok } from '@/sb-types';

type VideoBlokProps = {
  blok: SbBlokData<VideoStoryblok>;
}

const VideoWrapper = styled.video<VideoStoryblok>(({ theme, marginObj, paddingObj, sizeObj }) => ({
  ...marginObj,
  ...paddingObj,
  ...sizeObj,
  ...theme.utils.objStyleBreakpoints(marginObj, paddingObj, sizeObj),
}));


export const VideoBlok: FC<VideoBlokProps> = ({ blok }) => {
  const { marginObj, paddingObj, sizeObj, autoplay, controls, loop, muted, src } = blok;

  return (
    <div { ...storyblokEditable(blok) }>
      <VideoWrapper
        width={ sizeObj?.width }
        height={ sizeObj?.height }
        autoPlay={ !!autoplay }
        controls={ !!controls }
        loop={ !!loop }
        muted={ !!muted }
        marginObj={ marginObj }
        paddingObj={ paddingObj }
        style={{ width: sizeObj?.width || '100%', height: sizeObj?.height || 'auto' }}
        _uid={ '' } component={ 'video' }>
        <source src={ src?.filename || '' } type="video/mp4" />
        Your browser does not support the video tag.
      </VideoWrapper>
    </div>
  );
};
