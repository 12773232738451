/* eslint-disable no-console */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';
import type { AxiosResponse } from 'axios';
import { AxiosError } from 'axios';

import { getCookie } from '@/utils/cookies.util';

import signupApi from '../../requests/signup-api';
import type { SignUpErrorPayload, SignupPayload, SignupState, Td2DataResponse } from '../../types';
import { isSignUpError } from '../../types';
import { getUTMData } from '../../utils/utm.utils';

const trackRegistrationEvent = (email: string) => {
  const utmData = getUTMData();

  if (typeof window._hsq !== 'undefined') {
    window._hsq.push(['identify', { email, ...utmData,
      hs_analytics_first_url: utmData?.first_page_seen,
      first_page_seen_td: utmData?.first_page_seen }]);

    window._hsq.push([
      'trackCustomBehavioralEvent',
      {
        id: 'registration',
        value: utmData,
      },
    ]);
  }
};

const initialState: SignupState = {
  error: null,
  errorModal: null,
  errorMessage: null,
  userData: {},
  showModal: false,
  isLoadingData: false,
  showModalTry: false,
  showModalTrial: false,
  appWebUrl: `${ process.env.NEXT_PUBLIC_ONBOARDING_URL }`,
};

const handleHSSubmit = async (payload: {
  company: string;
  companySize: string;
  noWorkspaces: boolean;
  fullName: string;
  email: string;
  phone: string;
  userId:string;
  companyId:string,
  workspaceId:string,
  google_auth_sign_up: boolean,
  pageUri: string,
  pageName: string,
  utmData?: {
    utm_source: string | undefined,
    utm_medium: string | undefined,
    utm_campaign: string | undefined,
    utm_content: string | undefined,
    utm_term: string | undefined,
    first_page_seen: string | undefined
  },
  queryParams: { homeCTA: string} }) => {

  const { fullName, email, phone, companyId, companySize, company, userId, google_auth_sign_up, noWorkspaces, workspaceId, queryParams, pageName, pageUri, utmData } = payload;


  // Split full name into first and last name
  const [firstName, ...lastNameParts] = fullName.split(' ');
  const lastName = lastNameParts.join(' '); // Join remaining parts as the last name
  const hutk = getCookie('hubspotutk');
  const utmDataPayload = getUTMData();

  const formData = {
    firstname: firstName,
    lastname: lastName,
    email,
    company,
    phone,
    tdpeopleinthecompany: companySize,
    tduserid: userId,
    tdcompanyid: companyId,
    google_auth_sign_up,
    noWorkspaces,
    workspaceId,
    utmCampaign: queryParams.homeCTA,
    pageName,
    pageUri,
    hutk,
    utmData: utmData ?? utmDataPayload,
    // Add other fields as needed
  };

  try {
    await fetch(`${ process.env.NEXT_PUBLIC_TD_WEB_API_URL }/api/submit-hs-form`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });
  } catch (error) {
    Sentry.captureMessage('HS Form API Error', {
      extra: {
        error,
        level: 'warning',
      },
    });
    return false;
  }
};


const trySignup = createAsyncThunk<{
  td2: AxiosResponse<Td2DataResponse>,
  isModal: boolean,
},
  SignupPayload,
  {
    rejectValue: SignUpErrorPayload
  }>(
    'signup',
    async (signUpPayload: SignupPayload, { rejectWithValue }) => {
      try {
        const tdc = await signupApi.signupTdc(signUpPayload.data.email);
        if (tdc.data.user_exists) {
          if (!tdc.data.is_admin && !tdc.data.company_owner) {
            return rejectWithValue(
              {
                error: {
                  data: {
                    error: 'loginExistsTdc',
                    message: 'A user with given email is already registered.',
                  },
                },
                isModal: signUpPayload.isModal,
              } as SignUpErrorPayload,
            );
          }
        }

        const td2 = await signupApi.signupTd2(signUpPayload.data);
        let pageUri = '';
        let pageName = '';

        if (typeof window !== 'undefined') {
          // Access client-side objects
          pageUri = window.location.href;
          pageName = document.title;
        }

        if(signUpPayload.data.email) {
          trackRegistrationEvent(signUpPayload.data.email);
        }

        const hsSubmit = await handleHSSubmit({
          email: signUpPayload.data.email,
          fullName: signUpPayload.data.name,
          company: signUpPayload.data.company,
          phone: signUpPayload.data.splitTest.find(test => test.name === 'phone-number')?.value as string,
          companySize: signUpPayload.data.splitTest.find(test => test.name === 'company-size')?.value as string,
          userId: td2.data.data.userId as string,
          companyId: td2.data.data.companyId as string,
          workspaceId: td2.data.data.workspaceId as string,
          noWorkspaces: td2.data.data.noWorkspaces as boolean,
          queryParams: { homeCTA: signUpPayload.data.splitTest.find(test => test.name === 'popup-signup-trigger')?.value as string },
          google_auth_sign_up: false,
          pageUri,
          pageName,
          utmData: signUpPayload?.data?.utmData,
        });
        return { td2, isModal: signUpPayload.isModal, hsSubmit };
      } catch (e: unknown) {
        if (e instanceof AxiosError) {
          return rejectWithValue({ error: e.response, isModal: signUpPayload.isModal } as SignUpErrorPayload);
        }
        return rejectWithValue({ error: e, isModal: false } as SignUpErrorPayload);
      }
    },
  );


const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    reset: () => initialState,
    showModalTrial: state => {
      state.showModalTrial = true;
    },
    hideModalTrial: state => {
      state.showModalTrial = false;
    },
    failureModal: (state, action: PayloadAction<string>) => {
      state.errorModal = action.payload;
    },
    failure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    resetFailure: state => {
      state.error = null;
    },
    setAppWebUrl: (state, action: PayloadAction<string>) => {
      state.appWebUrl = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(trySignup.pending, state => {
        state.isLoadingData = true;
      })
      .addCase(trySignup.fulfilled, (state, action) => {
        const td2UserData = action.payload.td2.data ?? {};
        state.isLoadingData = false;
        redirectToOnboarding(state, td2UserData.data.token);
      })
      .addCase(trySignup.rejected, (state, { payload }) => {
        if (isSignUpError(payload?.error?.data) && payload) {
          if (payload.isModal) {
            state.errorModal = payload.error.data.error;
            state.errorMessage = payload.error.data.message;
          } else {
            state.error = payload.error.data.error;
            state.errorMessage = payload.error.data.message
          }
        }

        state.isLoadingData = false;
      });
  },
});

export const redirectToOnboarding = ({ appWebUrl }: SignupState, token: string) =>
  window.open(`${ appWebUrl }/?first-login=1&token=${ token }`, '_self');

export const redirectToAppWeb = (token: string) =>
  window.open(`${ process.env.NEXT_PUBLIC_APP_WEB_URL }/?token=${ token }`, '_self');

export const {
  reset,
  showModalTrial,
  hideModalTrial,
  failure,
  failureModal,
  setAppWebUrl,
  resetFailure,
} = signupSlice.actions;
export const signupThunks = {
  trySignup,
};
export default signupSlice.reducer;
