import { useEffect, useRef } from 'react';
import type { FeatureDefinition } from '@growthbook/growthbook';
import { ThemeProvider } from '@mui/material';
import { Analytics } from '@vercel/analytics/react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { useAppDispatch } from '@/hooks';
import { getMainDomain } from '@/utils/cookies.util';

import { GrowthBookProvider } from '../context/Growthbook/';
import { analyticsPage, page, pageInfo } from '../redux/features/trackingSlice';
import { wrapper } from '../redux/store';
import { initStoryblok } from '../storyblok/config';
import { theme } from '../theme';
import { captureUTMParameters } from '../utils/utm.utils';

import '../styles/index.scss';
import '../styles/scss/main.scss';

type GenericAppProps<P = unknown> = {
  pageProps: P;
  Component: NextPage<P>;
} & Omit<AppProps<P>, 'pageProps' | 'Component'>;

export type MyAppProps = {
  growthBookFeatures: Record<string, FeatureDefinition>
}

initStoryblok();

const MyApp = ({ Component, pageProps }: GenericAppProps<MyAppProps>) => {
  const dispatch = useAppDispatch();

  const router = useRouter();
  const didTrackPage = useRef(false);

  useEffect(() => {
    // Run UTM capture only once on initial load
    if (typeof window !== 'undefined' && !sessionStorage.getItem('utmCaptured')) {
      captureUTMParameters();
      sessionStorage.setItem('utmCaptured', 'true'); // Set flag to avoid recapture
    }
  }, []);

  useEffect(() => {
    if (didTrackPage.current) return;
    if (!Component.category) return;

    dispatch(pageInfo({
      name: Component.displayName || '',
      category: Component['category'] || '',
    }));
    dispatch(page(Component.displayName || ''));

    dispatch(analyticsPage({
      name: Component.displayName ?? router.asPath,
      category: Component['category'],
      properties: {
        name: Component.displayName ?? router.asPath,
        category: Component['category'],
      },
    }));

    didTrackPage.current = true;
  }, [router, Component, dispatch, pageProps.growthBookFeatures]);

  const siteUrl = `https://www.${ getMainDomain() }`;
  const canonicalUrl = (`${ siteUrl }` + (router.asPath === '/' ? '' : router.asPath)).split('?')[0];

  return (
    <>
      <Head>
        <link rel="canonical" href={ canonicalUrl } />
      </Head>
      <GrowthBookProvider features={ pageProps.growthBookFeatures }>
        <ThemeProvider theme={ theme }>
          <Component { ...pageProps } />
          <Analytics />
        </ThemeProvider>
      </GrowthBookProvider>
    </>
  );
};

export default wrapper.withRedux(MyApp);
